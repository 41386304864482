// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---readme-md": () => import("../../README.md" /* webpackChunkName: "component---readme-md" */),
  "component---pages-accesibility-fi-mdx": () => import("../../pages/accesibility-fi.mdx" /* webpackChunkName: "component---pages-accesibility-fi-mdx" */),
  "component---pages-agent-url-parameters-mdx": () => import("../../pages/agent-url-parameters.mdx" /* webpackChunkName: "component---pages-agent-url-parameters-mdx" */),
  "component---pages-customize-chatbox-mdx": () => import("../../pages/customize-chatbox.mdx" /* webpackChunkName: "component---pages-customize-chatbox-mdx" */),
  "component---pages-customize-css-mdx": () => import("../../pages/customize-css.mdx" /* webpackChunkName: "component---pages-customize-css-mdx" */),
  "component---pages-customize-tab-mdx": () => import("../../pages/customize-tab.mdx" /* webpackChunkName: "component---pages-customize-tab-mdx" */),
  "component---pages-exposed-events-mdx": () => import("../../pages/exposed-events.mdx" /* webpackChunkName: "component---pages-exposed-events-mdx" */),
  "component---pages-cookies-mdx": () => import("../../pages/cookies.mdx" /* webpackChunkName: "component---pages-cookies-mdx" */),
  "component---pages-fetch-and-push-info-mdx": () => import("../../pages/fetch-and-push-info.mdx" /* webpackChunkName: "component---pages-fetch-and-push-info-mdx" */),
  "component---pages-google-tag-manager-mdx": () => import("../../pages/google-tag-manager.mdx" /* webpackChunkName: "component---pages-google-tag-manager-mdx" */),
  "component---pages-loading-of-chat-mdx": () => import("../../pages/loading-of-chat.mdx" /* webpackChunkName: "component---pages-loading-of-chat-mdx" */),
  "component---pages-installation-mdx": () => import("../../pages/installation.mdx" /* webpackChunkName: "component---pages-installation-mdx" */),
  "component---pages-script-options-hash-mdx": () => import("../../pages/script-options-hash.mdx" /* webpackChunkName: "component---pages-script-options-hash-mdx" */),
  "component---pages-set-language-mdx": () => import("../../pages/set-language.mdx" /* webpackChunkName: "component---pages-set-language-mdx" */),
  "component---pages-script-options-mdx": () => import("../../pages/script-options.mdx" /* webpackChunkName: "component---pages-script-options-mdx" */),
  "component---pages-window-api-mdx": () => import("../../pages/window-api.mdx" /* webpackChunkName: "component---pages-window-api-mdx" */),
  "component---pages-set-team-context-mdx": () => import("../../pages/set-team-context.mdx" /* webpackChunkName: "component---pages-set-team-context-mdx" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

